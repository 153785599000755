.p-disclaimer {
  &-container {
    margin-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 120px;
    max-width: var(--emu-semantic-sizing-content-max-width);
    padding-left: 15px;
    padding-right: 15px;
    
    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-bottom: 80px;
      margin-top: 170px;
      padding-left: 0;
      padding-right: 0;
    }

    .cmp-title {
      margin-bottom: 20px;
    }

    li {
      color: var(--emu-semantic-colors-text-light);
      font-size: 16px;
      line-height: 28px;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        font-size: 18px;
        line-height: 32px;
      }
    }
  }

  header.header {
    background-color: var(--emu-semantic-colors-secondary-blue-100);
  }
}