.p-404 {
  &-container {
    margin-bottom: 60px;
    margin-top: 120px;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--emu-semantic-sizing-content-max-width);
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
  
    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-bottom: 90px;
      margin-top: 180px;
    }
  
    .text {
      margin-bottom: 60px;
  
      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-bottom: 90px;
      }

      h1 {
        color: var(--emu-semantic-colors-primary-blue-300);
      }
    }
  }

  header.header {
    background-color: var(--emu-semantic-colors-secondary-blue-100);
  }
}
