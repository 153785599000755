.c-progresscarousel {
  background-color: var(
    --emu-semantic-colors-transparent-secondary-grey-100-a10
  );
  margin-bottom: 40px;

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    margin-bottom: 60px;
  }

  > .title,
  > .carousel,
  > .text {
    max-width: var(--emu-semantic-sizing-content-max-width);

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  > .text {
    padding-bottom: 40px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      padding-bottom: 60px;
      padding-left: 0;
      padding-right: 0;
      padding-top: 30px;
    }

    p {
      font-size: 12px;
      line-height: 18px;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .cmp-title__text {
    padding-left: 15px;
    padding-bottom: 30px;
    padding-top: 40px;

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      padding-bottom: 35px;
      padding-left: 0;
      padding-top: 60px;
    }
  }

  .cmp-image__image {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .aaaem-carousel {
    &__content {
      display: flex;
    }

    &__item-container {
      display: flex;
      flex-direction: column;
      height: 100%;

      > .text {
        flex-grow: 1;
      }
    }

    .carouselslide {
      height: 100%;
    }

    .cmp-text {
      background-color: var(--emu-common-colors-white);
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      height: 100%;
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;

      p {
        font-size: 14px;
        line-height: 20px;

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
