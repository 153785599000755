footer {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--emu-semantic-colors-secondary-blue-100);
  position: relative;

  .c-footer {
    background-color: var(--emu-semantic-colors-secondary-blue-100);
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      max-width: var(--emu-semantic-sizing-content-max-width);
    }

    .text {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 15px;
      padding-right: 15px;

      .text {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
      }

      .cmp-text {
        &.aaaem-text {
          p {
            color: var(--emu-common-colors-white);
            opacity: 0.8;
          }

          ol {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;
            list-style-position: inside;
          }
        }
      }

      &:last-child {
        p {
          font-size: 12px;
          line-height: 18px;
          margin-top: 20px;
          margin-bottom: 20px;
          margin-left: 0;
          margin-right: 0;
          color: var(--emu-common-colors-white);
        }
      }
    }

    .container {
      .cmp-container {
        align-items: center;
        display: flex;
        justify-content: end;
        background-color: var(--emu-semantic-colors-secondary-blue-100);
        padding-top: 0;
        padding-bottom: 30px;
        padding-left: 0;
        padding-right: 0;

        .button {
          .aaaem-button {
            &.aaaem-button__primary-filled {
              background-color: var(--emu-common-colors-transparent);
              padding-top: 0;
              padding-bottom: 0;
              padding-left: 0;
              padding-right: 0;
              margin-left: 30px;

              &:active {
                outline: none;
              }

              &:active,
              &:hover {
                background: var(--emu-common-colors-transparent);
                color: var(--emu-common-colors-white);

                & svg {
                  fill: var(--emu-common-colors-white);
                }
              }

              svg {
                margin-right: 0;
                height: 30px;
                width: 30px;
              }
            }
          }

          &:first-child {
            flex-grow: 2;
          }
        }
      }

      .aaaem-container__position-static {
        padding-bottom: 0;

        .aem-Grid {
          border-top: 1px solid var(--emu-common-colors-white);

          .aem-GridColumn.aem-GridColumn--default--12:first-child {
            @include mq($emu-semantic-sizing-breakpoints-large) {
              padding-left: 0;
              padding-right: 0;
              width: 66%;
            }
          }

          .aem-GridColumn.aem-GridColumn--default--12:nth-child(2) {
            @include mq($emu-semantic-sizing-breakpoints-large) {
              width: 33%;
              float: right;
            }
          }
        }

        .c-footer-logos {
          flex-direction: column;
          align-items: center;

          @include mq($emu-semantic-sizing-breakpoints-large) {
            align-items: end;
            padding-top: 30px;
          }

          .image {
            margin-bottom: 30px;
          }

          img {
            height: 30px;

            @include mq($emu-semantic-sizing-breakpoints-xx-large) {
              height: 37px;
            }
          }

          .cmp-image__link {
            display: block;
            position: relative;
            z-index: var(--emu-common-other-z-index-layer);
          }
        }
      }
    }

    .container:nth-child(2) {
      > .cmp-container > .aem-Grid {
        .navigationlist:nth-child(3) {
          margin-top: 0;
          margin-left: 0;
          margin-right: 0;
          padding-top: 40px;
          z-index: var(--emu-common-other-z-index-layer);
          position: relative;
          border-top: 1px solid var(--emu-common-colors-white);
          padding-left: 0;
          padding-right: 0;

          @include mq($emu-semantic-sizing-breakpoints-large) {
            position: relative;
            text-align: center;
          }

          .emu-navigation {
            background-color: var(--emu-common-colors-transparent);
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;
          }

          .emu-navigation__menu--level-0 {
            display: block;
            margin-left: auto;
            margin-right: auto;
            max-width: 80%;
            padding-bottom: 0;
            padding-top: 0;
            text-align: center;

            .emu-navigation__item {
              padding-bottom: 10px;
              padding-right: 10px;
              padding-top: 0;
              display: inline-block;

              @include mq($emu-semantic-sizing-breakpoints-large) {
                margin-right: 0;
                padding-left: 10px;
              }

              .nav-item {
                border: 0;
                display: inline-block;
                padding-bottom: 0;
                padding-left: 0;
                padding-right: 0;
                padding-top: 0;
            
                &:hover {
                  background-color: var(--emu-common-colors-transparent);
                }

                a {
                  line-height: 18px;
                  font-size: 12px;
                  color: var(--emu-common-colors-white);
                  margin-left: 0;
                  margin-right: 0;
                  opacity: 0.8;
                  padding-bottom: 0;
                  padding-left: 0;
                  padding-right: 0;
                  padding-top: 0;
                  text-transform: none;
  
                  &:hover,
                  &:active {
                    text-decoration: underline;
                    color: var(--emu-common-colors-white);
                  }

                  &::before {
                    display: none;
                  }
                }
              }
            }
          }

          & + .text {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;

            @include mq($emu-semantic-sizing-breakpoints-large) {
              position: relative;
            }

          }

          & ~ .image {
            height: 377px;
            width: 100%;
            justify-content: center;
            display: flex;
            position: absolute;
            bottom: 0;
            right: 0;
            overflow: hidden;

            .p-home &,
            .p-results &,
            .p-qa &,
            .p-about & {
              bottom: -97px; /* height of the container below */

              @include mq($emu-semantic-sizing-breakpoints-xx-large) {
                bottom: -132px;
              }
            }

            .emu-image {
              width: 100%;
              height: 100%;

              svg {
                width: 100%;
                height: 100%;
                bottom: -8px;
                position: absolute;
                min-height: 377px;
                overflow: hidden;
                right: 0;

                @include mq($emu-semantic-sizing-breakpoints-large) {
                  height: 377px;
                  width: 373px;
                }
              }

              g {
                scale: 1.3;

                @include mq($emu-semantic-sizing-breakpoints-large) {
                  scale: 1.2;
                }
              }
            }
          }
        }
      }
    }
  }

  .emu-navigation {
    &__content-wrapper {
      position: relative;

      .emu-navigation {
        z-index: var(--emu-common-other-z-index-layer);
      }

      .emu-navigation__menu--level-0 {
        align-items: center;
        padding-bottom: 40px;
        padding-top: 30px;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          border-top: 0;
          margin-left: -14px;
        }

        .emu-navigation__item {
          &:not(:last-of-type) {
            padding-bottom: 10px;

            @include mq($emu-semantic-sizing-breakpoints-large) {
              margin-right: 106px;
              padding-bottom: 0;
            }
          }

          a {
            border: 0;
            font-weight: 500;
            margin-left: 15px;
            margin-right: 15px;
            padding-bottom: 10px;
            padding-left: 0;
            padding-right: 0;
            padding-top: 10px;
            position: relative;
            text-transform: uppercase;

            &::before {
              @include mq($emu-semantic-sizing-breakpoints-large) {
                background-color: var(--emu-common-colors-white);
                bottom: 0;
                content: "";
                height: 1px;
                left: 0;
                position: absolute;
                transition: width .2s ease-in-out;
                width: 0;
              }
            }

            &:hover {
              background-color: var(--emu-common-colors-transparent);

              &::before {
                @include mq($emu-semantic-sizing-breakpoints-large) {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
