.cmp-teaser {
  &.has-media {
    height: calc(100vh - 50px);

    &.teaser__height--65 {
      height: 65vh;

      @include aem-editor-view() {
        height: auto;
      }
    }

    @include aem-editor-view() {
      height: auto;
    }

    @include mq($emu-semantic-sizing-breakpoints-large) {
      height: 100vh;

      @include aem-editor-view() {
        height: auto;
      }
    }

    &:not(.has-video) {
      height: 50vh;

      @include aem-editor-view() {
        height: auto;
      }

      @include mq($emu-semantic-sizing-breakpoints-medium) {
        height: 75vh;

        @include aem-editor-view() {
          height: auto;
        }
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        height: 100vh;

        @include aem-editor-view() {
          height: auto;
        }
      }

      &.teaser__height--65 {
        height: 65vh;

        @include aem-editor-view() {
          height: auto;
        }
      }
    }
  }

  &.has-video {
    &::after {
      .p-home & {
        background: linear-gradient(to bottom, var(--emu-common-colors-white) 50%, var(--emu-semantic-colors-secondary-blue-200) 50%);
        bottom: -30px;
        content: "";
        display: block;
        height: 60px;
        left: 50%;
        position: absolute;
        width: 1px;
        z-index: var(--emu-common-other-z-index-layer);
      }
    }
  }

  &:not(.has-video) {
    &::after {
      background-color: var(--emu-semantic-colors-transparent-black-300);
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &__description {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;

    h1 {
      font-size: 40px;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        font-size: 60px;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        font-size: 80px;
      }
    }
    
    h2 {
      color: var(--emu-common-colors-white);
    }
  }

  .content {
    z-index: var(--emu-common-other-z-index-layer);

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      max-width: 83%;
    }

    &-container {
      max-width: var(--emu-semantic-sizing-content-max-width);
      width: auto;
  
      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-left: auto;
        margin-right: auto;
        padding-bottom: var(--emu-component-cards-teaser-padding-bottom-wide);
        padding-left: var(--emu-component-cards-teaser-padding-left-wide);
        padding-right: var(--emu-component-cards-teaser-padding-right-wide);
        padding-top: var(--emu-component-cards-teaser-padding-top-wide);
      }
    }
  }

  .teaser-video-controls {
    display: none;
  }
}
