.c-icontext {
  display: grid;
  max-width: var(--emu-semantic-sizing-content-max-width);
  margin-left: auto;
  margin-right: auto;

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    grid-template-columns: 50%;
    margin-bottom: 60px;
  }

  &.js-emu-inview {
    .cmp-title__text {
      animation-name: slideIn;
    }
  }

  > .title {
    background-color: var(--emu-semantic-colors-transparent-blue-300);
    margin-left: 15px;
    padding-bottom: 155px;
    padding-top: 40px;
    position: relative;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      grid-area: 1/2/2;
      margin-left: 0;
      padding-top: 116px;
      padding-bottom: 116px;
      width: 100%;
    }

    &::before {
      background-color: var(--emu-semantic-colors-transparent-blue-300);
      bottom: 0;
      content: '';
      height: 100%;
      left: -70%;
      position: absolute;
      width: 70%;
    }

    &::after {
      background-color: var(--emu-semantic-colors-transparent-blue-300);
      bottom: 0;
      content: '';
      height: 100%;
      left: 100%;
      position: absolute;
      width: 100vw;
    }

    .cmp-title__text {
      animation-delay: 0.3s;
      animation-duration: 0.8s;
      animation-fill-mode: forwards;
      opacity: 0;
      padding-right: 65px;
      position: relative;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        padding-right: 0;
      }

      &::after {
        background-color: var(--emu-semantic-colors-primary-blue-300);
        content: '';
        height: 1px;
        position: absolute;
        right: 0;
        top: 20px;
        width: 50px;

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          right: -80px;
          top: 35px;
        }
      }
    }
  }

  > .image {
    margin-right: 15px;
    margin-top: -135px;
    z-index: var(--emu-common-other-z-index-layer);

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      grid-area: 1/1/4/1;
      margin-right: 0;
      margin-top: 60px;
      max-width: 410px;
      order: 3;
    }
  }

  > .text {
    margin-bottom: 30px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 30px;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      grid-row: 2/3;
      margin-bottom: 0;
      margin-left: 0;
      margin-top: 15px;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-left: 0;
    }
  }

  &--right {
    > .title {
      margin-left: 15px;
      padding-right: 15px;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        grid-area: 1/1/2;
        padding-right: 0;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-left: 0;
      }

      &::before {
        left: unset;
        right: 100%;
        width: 100vw;
      }

      &::after {
        right: -70%;
        width: 70%;
      }

      .cmp-title__text {
        padding-left: 80px;
        padding-right: 0;

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          padding-left: 0;
        }

        &::after {
          left: -15px;

          @include mq($emu-semantic-sizing-breakpoints-x-large) {
            left: -65px;
          }

          @include mq($emu-semantic-sizing-content-max-width) {
            left: -80px;
          }
        }
      }
    }

    > .image {
      margin-left: 15px;
      margin-right: 0;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        grid-area: 1/2/4/2;
        margin-left: auto;
      }
    }

    > .text {
      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-left: 15px;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-left: 0;
      }
    }
  }

  &--rectangular {
    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      grid-template-columns: 75%;
    }

    > .title {
      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        padding-bottom: 68px;
      }

      &::before {
        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          left: -235%;
          width: 235%;
        }
      }

      .cmp-title__text {
        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          padding-right: 30px;
        }

        &::after {
          @include mq($emu-semantic-sizing-breakpoints-x-large) {
            right: -65px;
            top: 30px;
          }
        }
      }
    }

    > .image {
      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-right: 125px;
        max-width: 730px;
      }
    }

    &.c-icontext--right {
      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        grid-template-columns: 25%;
      }

      > .title {
        &::before {
          @include mq($emu-semantic-sizing-breakpoints-x-large) {
            left: unset;
            width: 100vw;
          }
        }

        &::after {
          @include mq($emu-semantic-sizing-breakpoints-x-large) {
            right: unset;
            width: 235%;
          }
        }
      }

      > .image {
        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          margin-left: 125px;
          margin-right: 0;
        }
      }
    }
  }
}
