.c-ba-carousel {
  margin-top: 60px;

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    margin-top: 80px;
  }

  &.js-emu-inview {
    > .container > .aaaem-container {
      animation-name: slideIn;
    }
  }

  > .container {
    background-color: var(--emu-semantic-colors-secondary-blue-400);
    padding-bottom: 30px;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      padding-bottom: 60px;
    }

    > .aaaem-container {
      animation-delay: 0.3s;
      animation-duration: 0.8s;
      animation-fill-mode: forwards;
      margin-left: auto;
      margin-right: auto;
      max-width: var(--emu-semantic-sizing-content-max-width);
      opacity: 0;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        display: flex;
        flex-direction: column;
      }

      > .container {
        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          &:first-child {
            order: 2;
          }

          &:nth-child(2) {
            order: 1;
          }

          &:last-child {
            order: 3;
          }
        }
      }

      > .text {
        order: 3;
      }
    }

    .aaaem-container {
      background-color: var(--emu-common-colors-transparent);
      max-width: var(--emu-semantic-sizing-content-max-width);
    }
  }

  .cmp-title {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--emu-semantic-sizing-content-max-width);

    &__text {
      margin-bottom: 30px;
      margin-left: 15px;
      margin-right: 15px;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-bottom: 100px;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &__text {
    background-color: var(--emu-semantic-colors-secondary-blue-400);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
    width: 100%;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      padding-right: 30px;
      width: 50%;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      padding-left: 0;
    }
  }

  &__footnote {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      padding-top: 0;
      width: calc(50% - 30px); /* -30px to add spacing to the right */
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      padding-left: 0;
      padding-right: 0;
    }

    p {
      font-size: 12px;
      line-height: 18px;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .aaaem-carousel {
    display: flex;
    flex-direction: column;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      flex-direction: row;
      padding-right: 15px;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      padding-right: 0;
    }

    .tns-inner {
      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        position: absolute;
        overflow: hidden;
        left: 50%;
      }
    }

    &__indicators {
      counter-reset: indicator-counter;
      display: flex !important; /* to overwrite the display: none added with JS by tiny slider when there is only one slide */
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-bottom: 30px;
      margin-top: 30px;
      padding-left: 15px;
      padding-right: 15px;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        align-content: flex-start;
        flex-basis: 50%;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 30px;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        padding-left: 0;
      }
    }

    &__indicator {
      border: 0;
      border-radius: 0;
      display: flex;
      height: 2px;
      position: relative;
      width: 60px;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-top: 30px;
        width: 80px;
      }

      &::after {
        color: var(--emu-semantic-colors-secondary-grey-100);
        counter-increment: indicator-counter;
        content: '0' counter(indicator-counter);
        display: block;
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 5px;
        position: absolute;
        text-align: left;
        text-indent: 0;
        top: -25px;
        width: 100%;
      }

      &.tns-nav-active {
        background-color: var(--emu-semantic-colors-secondary-blue-500);

        &::after {
          color: var(--emu-semantic-colors-primary-blue-300);
        }
      }

      &.show-progress {
        overflow: visible;

        span {
          height: 2px;
        }
      }
    }

    &__item {
      font-size: 0;

      &:not(.tns-slide-active) {
        opacity: 0;
      }
    }

    .tns-outer {
      order: 2;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        display: flex;
        margin-top: -60px;
      }
    }
  }
}
