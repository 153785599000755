header {
  &.header {
    background-color: var(--emu-common-colors-transparent);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: background-color 0.2s;
    width: 100%;
    z-index: var(--emu-common-other-z-index-header);

    &.header--sticky {
      animation: moveDown 0.5s ease;
      background-color: var(--emu-semantic-colors-secondary-blue-100);
      position: fixed;
    }

    .header {
      &__content {
        margin-left: auto;
      }
    
      &__logo {
        .emu-image__link {
          display: inline-block;
        }

        svg {
          height: 30px;
          width: auto;
    
          @include mq($emu-semantic-sizing-breakpoints-xx-large) {
            height: 37px;
          }
        }

        image {
          height: 100%;
        }
      }
    }

    .cmp-container-header {
      align-items: center;
      display: flex;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
      max-width: var(--emu-semantic-sizing-content-max-width);
      padding-left: 15px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 15px;
  
      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        height: auto;
        padding-bottom: 25px;
        padding-left: 0;
        padding-right: 0;
        padding-top: 25px;
      }
    }

    .c-header-burger.aaaem-button {
      align-items: center;
      height: 40px;
      padding-left: 20px;
      padding-right: 20px;

      &:active {
        background-color: var(--emu-common-colors-transparent);
        outline: none;
      }

      svg {
        height: 17px;
        margin-left: 7px;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        display: none;
      }
    }
  }
}

@keyframes moveDown {
  0% {
    top: -200px;
  }

  to {
    top: 0;
  }
}
