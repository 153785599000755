.c-mediatext {
  display: grid;
  grid-row-gap: 30px;
  margin-bottom: 60px;
  max-width: var(--emu-semantic-sizing-content-max-width);
  padding-left: 15px;
  padding-right: 15px;

  @include mq($emu-semantic-sizing-breakpoints-large) {
    grid-template-columns: 50%;
  }

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    margin-left: auto;
    margin-right: auto;
  }

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    margin-bottom: 80px;
    padding-left: 0;
    padding-right: 0;
  }

  > .title,
  > .text,
  > .button {
    @include mq($emu-semantic-sizing-breakpoints-large) {
      max-width: 85%;
      padding-left: 15%;

      .c-treatmentareas & {
        max-width: none;
      }
    }
  }

  > .title {
    @include mq($emu-semantic-sizing-breakpoints-large) {
      grid-area: 2/2/2;
    }
  }

  > .image,
  > .comparisonslider {
    animation-delay: 0.3s;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    display: flex;
    margin-left: -15px;
    opacity: 0;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      grid-area: 1/1/6/1;
      margin-bottom: 0;
      margin-right: 20px;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-left: -70px;
    }

    img {
      aspect-ratio: 5/6;
      object-fit: cover;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        aspect-ratio: 369/461;
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        aspect-ratio: 25/26;
      }
    }
  }

  > .text {
    @include mq($emu-semantic-sizing-breakpoints-large) {
      grid-row: 3/4;

      .c-treatmentareas & {
        grid-row: 1/4;
      }

      .cmp-text {
        .c-treatmentareas & {
          border-top: 1px solid var(--emu-semantic-colors-transparent-black-200);
          padding-top: 30px;
        }
      }
    }

    ol {
      list-style-position: inside;
      padding-left: 0;
    }

    li {
      color: var(--emu-semantic-colors-text-light);
      font-size: 16px;
      line-height: 28px;
      padding-bottom: 5px;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        font-size: 18px;
        line-height: 32px;
      }
    }
  }

  > .button {
    @include mq($emu-semantic-sizing-breakpoints-large) {
      grid-row: 4/5;
    }
  }

  &.c-mediatext--rtl {
    > .title,
    > .text,
    > .button {
      @include mq($emu-semantic-sizing-breakpoints-large) {
        max-width: 85%;
        padding-left: 0;
        padding-right: 15%;
      }
    }

    > .title {
      @include mq($emu-semantic-sizing-breakpoints-large) {
        grid-area: 2/1/2;
      }
    }

    > .image,
    > .comparisonslider {
      margin-left: 0;
      margin-right: -15px;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        grid-area: 1/2/6/2;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-left: 0;
      }
    }
  }

  &.js-emu-inview {
    > .image,
    > .comparisonslider {
      animation-name: revealFromBottom;
    }
  }
}
