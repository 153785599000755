// sticky banner component at the bottom of the footer
// Developed for AU as a part of AAGSB-18.
.c-footer-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: var(--emu-common-other-z-index-isi);
  background-color: var(--emu-semantic-colors-primary-blue-300);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 10px;
  width: 100%;

  p {
    font-size: 9px;
    line-height: 1;
    color: var(--emu-common-colors-white);
    letter-spacing: 0.05px;
  }
}
