.aaaem-button {
  &__primary-filled,
  &__primary-outline,
  &__secondary-filled {
    font-weight: 500;
    text-transform: uppercase;
  }

  &__primary-filled {
    &:active,
    &:focus,
    &:hover {
      background: linear-gradient(270deg, var(--emu-semantic-colors-primary-blue-300) 0%, var(--emu-semantic-colors-primary-blue-200) 100%);
    }

    &:visited {
      color: var(--emu-common-colors-white);
    }
  }

  &__secondary-filled {
    &:active,
    &:focus,
    &:hover {
      background: linear-gradient(270deg, var(--emu-semantic-colors-secondary-yellow-100) 0%, var(--emu-semantic-colors-secondary-yellow-200) 100%);
    }

    &:visited {
      color: var(--emu-semantic-colors-primary-blue-300);
    }
  }

  &__primary-outline {
    transition: background-color 0.3s;
  }
}