.c-topicnavigation {
  margin-bottom: 60px;

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    margin-bottom: 80px;
  }

  &::after {
    background: var(--emu-semantic-colors-secondary-grey-200);
    bottom: 0;
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
  }

  &__section {
    overflow: hidden;
  }
  
  &__wrapper {
    position: sticky;
    top: 60px;
    z-index: var(--emu-common-other-z-index-overlay);

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      top: 90px;
    }
  }

  &__container {
    @include mq($emu-semantic-sizing-breakpoints-large) {
      display: flex;
      justify-content: space-between;
      margin-left: auto;
      margin-right: auto;
      max-width: var(--emu-semantic-sizing-content-max-width);
    }

    > .text {
      .cmp-text {
        display: none;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          color: var(--emu-semantic-colors-text-light);
          display: block;
          font-size: 18px;
          line-height: 32px;
          margin-left: 15px;
          margin-right: 35px;
          opacity: 0.6;
          padding-bottom: 16px;
          padding-top: 16px;
          white-space: nowrap;
        }

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          margin-left: 0;
        }
      }
    }
  }

  .emu-navigation {
    &__content-wrapper {
      background-color: var(--emu-semantic-colors-none);
      position: relative;

      .emu-navigation {
        background-color: var(--emu-semantic-colors-none);
      }
    }

    &__menu--level-0 {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: scroll;
      scroll-behavior: smooth;
      scrollbar-width: none;
      scroll-snap-type: x mandatory;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__item {
      flex-shrink: 0;

      &:not(:first-of-type) {
        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          margin-left: 46px;
        }
      }

      a {
        border: 0;
        color: var(--emu-semantic-colors-primary-blue-300);
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 3px;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 15px;
        padding-bottom: 15px;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        position: relative;

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          font-size: 18px;
          line-height: 32px;
        }

        &:hover {
          background-color: var(--emu-semantic-colors-none);
          color: var(--emu-semantic-colors-primary-blue-300);

          @include mq($emu-semantic-sizing-breakpoints-large) {
            color: var(--emu-semantic-colors-primary-blue-300);

            &::after {
              transform: scaleX(1);
            }
          }
        }

        &::after {
          background-color: var(--emu-semantic-colors-primary-blue-300);
          bottom: -3px;
          content: "";
          display: block;
          height: 2px;
          left: 0;
          position: absolute;
          transform: scaleX(0);
          transition: 0.3s ease;
          width: 100%
        }
      }

      &--active {
        a {
          color: var(--emu-semantic-colors-primary-blue-300);

          &:hover {
            color: var(--emu-semantic-colors-primary-blue-300);
          }

          &::after {
            transform: scaleX(1);
          }
        }
      }
    }
  }
}