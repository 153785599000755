.p-about-container {
  .about-section {
    > .text {
      margin-left: 15px;
      margin-right: 15px;
      max-width: var(--emu-semantic-sizing-content-max-width);

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-left: auto;
        margin-right: auto;
      }

      p {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}