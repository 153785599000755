@font-face {
  font-family: 'Avenir-Next';
  font-display: swap;
  font-weight: bold;
  src: url('./assets/fonts/AvenirNext/AvenirNext-Bold.woff2') format('woff2'),
    url('./assets/fonts/AvenirNext/AvenirNext-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Avenir-Next';
  font-display: swap;
  font-style: italic;
  src: url('./assets/fonts/AvenirNext/AvenirNext-Italic.woff2') format('woff2'),
    url('./assets/fonts/AvenirNext/AvenirNext-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Avenir-Next';
  font-display: swap;
  font-weight: 500;
  src: url('./assets/fonts/AvenirNext/AvenirNext-Medium.woff2') format('woff2'),
    url('./assets/fonts/AvenirNext/AvenirNext-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Avenir-Next';
  font-display: swap;
  font-style: italic;
  font-weight: 500;
  src: url('./assets/fonts/AvenirNext/AvenirNext-MediumItalic.woff2') format('woff2'),
    url('./assets/fonts/AvenirNext/AvenirNext-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Avenir-Next';
  font-display: swap;
  src: url('./assets/fonts/AvenirNext/AvenirNext-Regular.woff2') format('woff2'),
    url('./assets/fonts/AvenirNext/AvenirNext-Regular.woff') format('woff');
}
