.c-footnotes {
  margin-bottom: 60px;
  margin-left: 15px;
  margin-right: 15px;
  max-width: var(--emu-semantic-sizing-content-max-width);

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    margin-bottom: 80px;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    font-size: 12px;
    line-height: 18px;

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
