.c-navigation {
  background-color: var(--emu-semantic-colors-secondary-blue-100);
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    align-items: center;
    background-color: var(--emu-common-colors-transparent);
    flex-direction: row;
    padding: 0;
    position: relative;
    transform: none;
    transition: none;
  }

  &--mobile {
    .modal-wrapper {
      display: block;
      transform: translateX(100%);
      transition: transform 0.35s ease-in-out;

      &.is-open {
        transform: translateX(0);
      }
    }

    .modal-content {
      background-color: var(--emu-semantic-colors-secondary-blue-100);
      height: 100%;
      margin: 0;
      max-width: none;
      padding: 0;
      width: 100%;

      .main-close {
        color: var(--emu-common-colors-white);
        font-weight: normal;

        &:hover,
        &:focus {
          opacity: 1;
        }
      }

      > .container {
        height: 100%;

        > .aaaem-container {
          height: 100%;

          > .container {
            height: 100%;

            > .aaaem-container {
              height: 100%;

              > .button {
                bottom: 40px;
                position: absolute;

                .aaaem-button {
                  color: var(--emu-common-colors-white);
                  font-weight: 500;
                }
              }

              > .container > .aaaem-container > .button {
                margin-bottom: 20px;

                .c-navigation-hp-link {
                  color: var(--emu-common-colors-white);
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }
  }
  
  &--desktop {
    display: none;

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      display: flex;
    }
  }

  &-logo {
    margin-top: 5px;

    svg {
      display: block;
      height: 30px;
      width: auto;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      display: none;
    }
  }

  &-wrapper-items.aaaem-container {
    margin-top: 30px;

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      align-items: center;
      background-color: var(--emu-common-colors-transparent);
      display: flex;
      gap: 30px;
      margin-right: 30px;
      margin-top: 0;
    }
  }

  &-wrapper-top {
    .emu-image__link {
      display: inline-block;
  
      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        display: block;
      }
    }

    svg,
    image {
      height: auto;
      width: 135px;
    }
  }

  &-wrapper-top,
  &-wrapper-bottom {
    background-color: var(--emu-semantic-colors-secondary-blue-100);
    display: flex;
    justify-content: space-between;

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      background-color: initial;
      display: block;
    }
  }

  &-wrapper-bottom {
    align-items: center;
    border-top: 1px solid var(--emu-common-colors-white);
    padding-top: 40px;

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      background-color: var(--emu-common-colors-transparent);
      border-top: 0;
      display: flex;
      gap: 30px;
      padding-top: 0;
    }

    > .button:nth-child(2) {
      .aaaem-button {
        color: var(--emu-common-colors-white);
        font-weight: 500;
      }
    }
  }

  .emu-navigation {
    &__content-wrapper {
      left: initial;
      position: relative;
      top: initial;

      nav {
        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          background-color: var(--emu-common-colors-transparent);
        }
      }
    }

    &__item {
      a {
        border: 0;
        font-weight: 500;
        position: relative;
        text-transform: uppercase;

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          margin-left: 0;
          padding-left: 0;
          padding-right: 0;
        }

        &:hover {
          background-color: var(--emu-common-colors-transparent);

          &::before {
            @include mq($emu-semantic-sizing-breakpoints-xx-large) {
              width: 100%;
            }
          }
        }

        &::before {
          @include mq($emu-semantic-sizing-breakpoints-xx-large) {
            background-color: var(--emu-common-colors-white);
            content: "";
            height: 1px;
            width: 0;
            transition: width .2s ease-in-out;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }
    }

    &__menu--level-0 {
      align-items: center;
      border-top: 1px solid var(--emu-common-colors-white);
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
      padding-top: 20px;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        border-top: 0;
        flex-direction: row;
        padding-bottom: 0;
        padding-top: 0;
      }

      .emu-navigation__item:not(:first-of-type) {
        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          margin-left: 40px;
        }
      }
    }
  }

  &-findaclinic.aaaem-button {
    height: 40px;
    padding-bottom: 4px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 4px;

    &:active {
      background-color: var(--emu-common-colors-transparent);
      outline: none;
    }

    &:focus,
    &:hover {
      background-color: var(--emu-semantic-colors-transparent-white-100);
    }
  }

  &-hp-link {
    &:hover {
      svg {
        fill: var(--emu-common-colors-white);
      }
    }

    svg {
      fill: var(--emu-common-colors-white);
      height: 14px;
      margin-right: 5px;
      transform: rotate(315deg);
    }
  }

  .cmp-languagenavigation {
    position: relative;
    z-index: var(--emu-common-other-z-index-modal);

    &__switcher {
      color: var(--emu-common-colors-white);
      display: inline-flex;
      font-weight: 500;

      &.active {
        svg {
          transform: scaleY(-1);
        }
      }

      span {
        align-items: center;
        display: flex;
        margin-left: 5px;
      }

      svg {
        display: inline-block;
        height: 20px;
        transform: scaleY(1);
        transition: all 0.2s ease-in-out;
        width: 20px;
      }
    }

    &__group {
      background-color: var(--emu-common-colors-white);
      display: flex;
      flex-direction: column;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      transition: all 0.2s ease-in-out;
      transform: translateY(-100%);

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-top: 13px;
        top: initial;
        transform: translateY(-20px);
      }

      &.active {
        opacity: 1;
        pointer-events: all;
        transform: translateY(calc(-100% - 12px));

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          transform: translateY(0);
        }
      }
    }

    &__item-link {
      display: block;
      font-size: 16px;
      line-height: 25px;
      padding-bottom: 10px;
      padding-left: 14px;
      padding-right: 14px;
      padding-top: 10px;
      text-decoration: none;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        color: var(--emu-semantic-colors-primary-blue-300);
      }

      &:hover {
        background-color: var(--emu-common-colors-white);

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          background-color: var(--emu-common-colors-white);
          box-shadow: inset 3px 0 0 -1px var(--emu-semantic-colors-primary-blue-300);
        }
      }
    }
  }
}