.find-a-clinic-page .c-testimonial-video-carousel {
  position: relative;
  padding-bottom: 60px;
  padding-top: 60px;

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    padding-bottom: 120px;
    padding-top: 120px;
  }

  &::before {
    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      background-color: var(--emu-semantic-colors-transparent-secondary-grey-100-a10);
      bottom: 0;
      content: '';
      height: 68%;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  .aaaem-container{
    background-color: transparent;
  }

  .carousel,
  .title {
    margin-left: 15px;
    max-width: var(--emu-semantic-sizing-content-max-width);
    overflow: hidden;

    @include mq($emu-semantic-sizing-content-max-width) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  .title {
    margin-bottom: 20px;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      margin-bottom: 60px;
    }
  }

  .carousel.panelcontainer {
    .aaaem-carousel {
      display: flex;
      flex-direction: column;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        flex-direction: row;
        justify-content: space-between;
      }

      .tns-ovh {
        overflow: visible;
      }

      &__actions {
        display: inline-flex;
        justify-content: flex-end;
        margin-bottom: 20px;
        padding-right: 15px;
        
        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          margin-right: 70px;
          padding-right: 0;
        }
      }

      &__action {
        background-color: var(--emu-common-colors-transparent);
        border-color: var(--emu-semantic-colors-primary-blue-300);
        border-radius: 50%;
        height: 50px;
        overflow: hidden;
        position: relative;
        width: 50px;

        &::before {
          background-repeat: no-repeat;
          content: "";
          display: inline-block;
          height: 100%;
          position: inherit;
          right: -6px;
          top: 7px;
          width: 100%;

        }
    
        &::after {
          background: linear-gradient(90deg, var(--emu-semantic-colors-transparent-primary-blue-300-a30), var(--emu-common-colors-transparent));
          content: "";
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          transform: translateX(-100%);
          transition: 0.3s;
          width: 100%;
        }
    
        &:hover {
          &::after {
            transform: translateX(0);
          }
        }
      
        &-prev {
          margin-right: 10px;

          @include mq($emu-semantic-sizing-breakpoints-x-large) {
            margin-right: 20px;
          }

          &::before {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='19px' height='19px' viewBox='0 0 19 19' version='1.1'%3e%3ctitle%3eIcons/Arrows/Left Arrow%3c/title%3e%3cg id='Symbols' stroke='%23005EB8' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='Group-3' stroke='%23005EB8' transform='translate(9.500000%2c 9.500000) scale(-1%2c 1) translate(-9.500000%2c -9.500000) '%3e%3cg id='Group-2' transform='translate(3.000000%2c 4.000000)'%3e%3cline x1='0' y1='5.5' x2='12.5' y2='5.5' id='Path-2'/%3e%3cpolyline id='Path-3' points='7 0 12.5 5.5 7 11'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
          }
        }
      
        &-next::before {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='19px' height='19px' viewBox='0 0 19 19' version='1.1'%3e%3ctitle%3eIcons/Arrows/Right Arrow%3c/title%3e%3cg id='Symbols' stroke='%23005EB8' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='Icons/Arrows/Right-Arrow' stroke='%23005EB8'%3e%3cline x1='3' y1='9.5' x2='15.5' y2='9.5' id='Path-2'/%3e%3cpolyline id='Path-3' points='10 4 15.5 9.5 10 15'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
        }

        &.disabled {
          opacity: 0.25;
          pointer-events: none;
          cursor: auto;
        }

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          bottom: unset;
        }

        span {
          display: none;
        }
      }

      &__indicators {
        display: none;
      }

      &__item-container {
        .emu-embed {
          margin-right: 20px;

          @include mq($emu-semantic-sizing-breakpoints-x-large) {
            margin-right: 190px;
          }
        }

        .text {
          p {
            font-size: 14px;
            line-height: 20px;
            margin-top: 20px;
          }
          
          + .text {
            p {
              font-family: var(--emu-semantic-font-families-heading);
              font-size: 27px;
              font-style: italic;
              line-height: 35px;
              margin-bottom: 19px;
              margin-top: 23px;
            }
          }
        }
      }
    }
  }
}
