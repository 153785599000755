.c-qacontainer {
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  max-width: var(--emu-semantic-sizing-content-max-width);

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    display: grid;
    grid-template-columns: 50%;
    margin-bottom: 60px;
    margin-top: 60px;
  }

  > .text {
    z-index: var(--emu-common-other-z-index-layer);

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      grid-area: 1/2;
    }
  }

  .cmp-title {
    animation-delay: 0.3s;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    background-color: var(
      --emu-semantic-colors-transparent-primary-blue-300-a10
    );
    margin-bottom: 20px;
    margin-right: 15px;
    opacity: 0;
    padding-bottom: 35px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 35px;
    position: relative;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      padding-bottom: 60px;
      padding-top: 60px;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }

    &::before {
      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        background-color: var(
          --emu-semantic-colors-transparent-primary-blue-300-a10
        );
        content: '';
        height: 100%;
        position: absolute;
        right: 100%;
        top: 0;
        width: 100vw;
      }
    }

    &::after {
      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        background-color: var(
          --emu-semantic-colors-transparent-primary-blue-300-a10
        );
        content: '';
        height: 100%;
        left: 100%;
        position: absolute;
        top: 0;
        width: 200px;
      }
    }

    &__text {
      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        max-width: 445px;
      }
    }
  }

  .cmp-image {
    animation-delay: 0.3s;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    margin-bottom: 30px;
    margin-right: 15px;
    opacity: 0;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      margin-bottom: 0;
      margin-right: 0;
    }
  }

  .cmp-text {
    margin-left: 15px;
    margin-right: 15px;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      margin-left: 30px;
      margin-top: 115px;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-right: 0;
    }

    p {
      font-size: 16px;
      line-height: 28px;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        font-size: 18px;
        line-height: 32px;
      }
    }
  }

  &--image {
    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      column-gap: 125px;
    }

    > .title {
      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        grid-area: 1/2;
      }
    }

    > .image {
      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        grid-area: 1/1/4/1;
      }
    }

    > .text {
      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        grid-area: 2/2;
      }
    }

    .cmp-title {
      margin-bottom: 30px;
      margin-left: 15px;
      margin-right: 0;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-bottom: 0;
        margin-left: 0;
        margin-top: 60px;
        padding-bottom: 120px;
        padding-left: 0;
        padding-top: 60px;
      }

      &::before {
        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          background-color: var(
            --emu-semantic-colors-transparent-primary-blue-300-a10
          );
          content: '';
          height: 100%;
          left: -150px;
          position: absolute;
          top: 0;
          width: 150px;
        }
      }

      &::after {
        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          background-color: var(
            --emu-semantic-colors-transparent-primary-blue-300-a10
          );
          content: '';
          height: 100%;
          position: absolute;
          left: 100%;
          top: 0;
          width: 100vw;
        }
      }
    }

    .cmp-image {
      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-left: -80px;
      }
    }

    .cmp-text {
      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-left: 0;
        margin-top: -105px;
      }
    }
  }

  &--right {
    > .title {
      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        grid-area: 1/2;
      }
    }

    > .text {
      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        grid-area: 1/1;
      }
    }

    .cmp-title {
      margin-left: 15px;
      margin-right: 0;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-left: 0;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        padding-right: 0;
      }

      &::before {
        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          width: 200px;
        }
      }

      &::after {
        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          width: 100vw;
        }
      }

      &__text {
        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          margin-left: auto;
        }
      }
    }

    .cmp-text {
      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-left: 15px;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-left: 0;
      }
    }
  }

  &.js-emu-inview {
    .cmp-title {
      animation-name: slideIn;
    }

    .cmp-image {
      animation-name: revealFromBottom;
    }
  }
}
