.p-contact-us {
  &-container {
    margin-top: 120px;
    
    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-top: 180px;
    }
  
    > .title,
    > .text {
      margin-left: auto;
      margin-right: auto;
      max-width: var(--emu-semantic-sizing-content-max-width);
    }
  
    > .title {
      margin-bottom: 20px;
  
      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-bottom: 40px;
      }
    }
  
    > .text {
      margin-bottom: 60px;
      padding-left: 15px;
      padding-right: 15px;
  
      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-bottom: 90px;
      }
  
      .aaaem-text {
        @include mq($emu-semantic-sizing-breakpoints-large) {
          margin-left: 8%;
          max-width: 83%;
        }
      }
    }

    h1 {
      color: var(--emu-semantic-colors-primary-blue-300);
      text-align: center;
    }
  }

  header.header {
    background-color: var(--emu-semantic-colors-secondary-blue-100);
  }
}