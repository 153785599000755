body {
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-semantic-font-weight-regular);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-bold);
}

h2,
h3,
h4,
h5,
h6 {
  color: var(--emu-semantic-colors-primary-blue-300);
}

h1 {
  font: var(--emu-component-ingredients-title-h1-typography-narrow);
  color: var(--emu-common-colors-white);

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    font: var(--emu-component-ingredients-title-h1-typography-wide);
  }
}

h2 {
  font: var(--emu-component-ingredients-title-h2-typography-narrow);

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    font: var(--emu-component-ingredients-title-h2-typography-wide);
  }
}

h3 {
  font: var(--emu-component-ingredients-title-h3-typography-narrow);

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    font: var(--emu-component-ingredients-title-h3-typography-wide);
  }
}

h4 {
  font: var(--emu-component-ingredients-title-h4-typography-narrow);

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    font: var(--emu-component-ingredients-title-h4-typography-wide);
  }
}

h5 {
  font: var(--emu-component-ingredients-title-h5-typography-narrow);

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    font: var(--emu-component-ingredients-title-h5-typography-wide);
  }
}

p {
  font: var(--emu-component-ingredients-text-text-typography-narrow);

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    font: var(--emu-component-ingredients-text-text-typography-wide);
  }
}

sup {
  line-height: 0;
  font-size: 50%;
}
