.c-keytakeaways {
  background-color: var(--emu-semantic-colors-transparent-primary-blue-300-a10);
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 40px;

  @include mq($emu-semantic-sizing-breakpoints-large) {
    padding-bottom: 40px;
  }

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    padding-bottom: 60px;
    padding-top: 60px;
    padding-left: 60px;
    padding-right: 60px;
  }

  &__container {
    background-color: var(--emu-common-colors-transparent);
    margin-left: auto;
    margin-right: auto;
    max-width: var(--emu-semantic-sizing-content-max-width);

    @include mq($emu-semantic-sizing-breakpoints-large) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    > .title:first-child {
      @include mq($emu-semantic-sizing-breakpoints-large) {
        padding-bottom: 35px;
        flex: 0 0 100%;
      }
    }

    > .title:not(:first-child) {
      position: relative;
      line-height: 32px;

      &:not(:last-of-type) {
        @include mq($emu-semantic-sizing-breakpoints-large) {
          margin-bottom: 0;
        }
      }

      &::after {
        background-color: var(--emu-semantic-colors-primary-blue-300);
        bottom: 0;
        width: 30px;
        content: '';
        display: block;
        height: 1px;
        left: 0;
        position: absolute;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          left: 15px;
        }

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          left: 0;
        }
      }

      @include mq($emu-semantic-sizing-breakpoints-large) {
        flex: 0 0 33%;
      }
    }
  }

  .cmp-title {
    padding-bottom: 15px;
    position: relative;
    margin-bottom: 15px;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      margin-left: 0;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 5px;
    }

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      margin-bottom: 5px;
      margin-right: 8%;
      padding-bottom: 10px;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      padding-left: 0;
      padding-right: 0;
    }

    &__text {
      line-height: 28px;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        line-height: 32px;
      }
    }

    sup {
      font-weight: 500;
    }
  }
}
