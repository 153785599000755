.p-clinics,
.p-clinic {
  .modal-content {
    background-color: var(--emu-common-colors-white);
    text-align: center;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    padding-left: 20px;
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
    width: 100%;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      max-width: 610px;
      position: relative;
      bottom: initial;
      margin-bottom: 15%;

    }

    h1 {
      font: var(--emu-semantic-typography-wide-headings-xl);
      color: var(--emu-semantic-colors-primary-blue-300);
      letter-spacing: -2px;
    }

    .close {
      color: var(--emu-semantic-colors-primary-blue-300);
      font-size: var(--emu-semantic-font-sizes-narrow-xl);
    }

    &__header,
    &__message {
      max-width: 350px;
      margin-right: auto;
      margin-left: auto;
    }

    &__header {
      margin-top: 40px;
    }

    &__message {
      color: var(--emu-component-ingredients-text-text-color-light);
      padding-top: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      padding-left: 20px;
    }

    &__cta {
      border: 1px solid;
      border-radius: 25px;
      cursor: pointer;
      font-weight: 500;
      padding-bottom: 13px;
      padding-left: 27px;
      padding-right: 27px;
      padding-top: 13px;

      &-container {
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
        padding-top: 20px;
      }

      &--confirm {
        background-color: var(--emu-component-actions-button-color-background-primary-filled-dark);
        color: var(--emu-component-actions-button-text-color-primary-filled-dark);
        margin-right: 10px;

        &:active,
        &:focus,
        &:hover {
          background: linear-gradient(270deg, var(--emu-semantic-colors-primary-blue-300) 0%, var(--emu-semantic-colors-primary-blue-200) 100%);
        }
      }

      &--cancel {
        border-color: var(--emu-semantic-colors-primary-blue-300);
        color: var(--emu-semantic-colors-primary-blue-300);

        &:active,
        &:focus,
        &:hover {
          background: linear-gradient(270deg, var(--emu-semantic-colors-transparent-white-300) 0%, var(--emu-semantic-colors-transparent-primary-blue-300-a30) 100%);
        }
      }
    }
  }

  .iclfeed__modal,
  .icldetail__modal {
    .modal-content {
      > .container {
        margin-top: 30px;

        .cmp-title__text {
          margin-bottom: 20px;
          text-align: center;
        }

        .cmp-text {
          margin-bottom: 20px;
        }
      }
    }

    .modal-cta-container {
      [data-ref="close-cta"] {
        order: 1;
      }
    }

    .modal__cta--confirm {
      border: 1px solid var(--emu-semantic-colors-primary-blue-300);
      border-radius: 25px;
      color: var(--emu-semantic-colors-primary-blue-300);
      display: block;
      padding-bottom: 8px;
      padding-left: 27px;
      padding-right: 27px;
      padding-top: 8px;
    }

    .modal__cta--cancel {
      &:active,
      &:focus,
      &:hover {
        background: linear-gradient(270deg, var(--emu-semantic-colors-primary-blue-300) 0%, var(--emu-semantic-colors-primary-blue-200) 100%);
      }
    }
  }
}