.c-3up {
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--emu-semantic-sizing-content-max-width);

  .carousel {
    border-bottom: 1px solid var(--emu-semantic-colors-secondary-grey-100);
    border-top: 1px solid var(--emu-semantic-colors-secondary-grey-100);
    padding-bottom: 20px;
    padding-top: 20px;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      padding-bottom: 30px;
      padding-top: 30px;
    }
  }

  .tns-inner {
    scrollbar-width: none;
    -ms-overflow-style: none;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      overflow: auto;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .aaaem-carousel {
    &__content {
      display: flex;
    }

    &__item {
      padding-bottom: 20px;
      padding-top: 20px;
      position: relative;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        padding-bottom: 30px;
        padding-top: 30px;
      }

      &:not(:last-of-type) {
        &::before {
          background-color: var(--emu-semantic-colors-secondary-grey-100);
          content: '';
          display: block;
          height: 100%;
          position: absolute;
          right: 15px;
          top: 0;
          width: 1px;

          @include mq($emu-semantic-sizing-breakpoints-large) {
            right: 30px;
          }
        }
      }
    }
  }

  .cmp-text {
    p {
      font-size: 14px;
      line-height: 20px;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  h3 {
    font-weight: bold;
    margin-bottom: 20px;
  }
}
