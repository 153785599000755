.p-qa-container {
  .c-topicnavigation {
    margin-bottom: 0;
  }

  .c-topicnavigation__section {
    > .title {
      margin-left: 15px;
      margin-right: 15px;
      margin-top: 60px;
      max-width: var(--emu-semantic-sizing-content-max-width);

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-left: auto;
        margin-right: auto;
        margin-top: 80px;
      }

      + .container {
        .c-qacontainer {
          margin-top: 20px;

          @include mq($emu-semantic-sizing-breakpoints-xx-large) {
            margin-top: 25px;
          }
        }
      }
    }
  }

  .c-find-the-clinic-video {
    margin-top: 20px;

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-top: 60px;
    }
  }
}