.icl-tabs {
  &.aaaem-tabs {
    margin: 0;
  }

  .aaaem-tabs {
    &__tablist {
      display: flex;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        display: none;
      }
    }

    &__tab {
      flex: 1 1 50%;
      height: 49px;
      font-size: 18px;
      line-height: 1.67;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      color: var(--emu-semantic-colors-primary-purple-100);

      &:first-child {
        border-right-width: 1px;
        border-right-style: solid;
        border-right-color: var(--emu-semantic-colors-transparent-primary-blue-300-a25);
      }

      .tab-icon {
        margin-right: 10px;
        padding-top: 2px;
        height: 22px;
      }

      a {
        background-color: var(--emu-semantic-colors-transparent-primary-blue-300-a10);
        border-bottom: 1px solid var(--emu-semantic-colors-primary-blue-300);
        color: var(--emu-semantic-colors-primary-blue-300);

        &:hover {
          background-color: var(--emu-semantic-colors-transparent-primary-blue-300-a10);
        }
      }

      a[aria-selected=true] {
        border: none;
        background-color: var(--emu-common-colors-white);
        font-weight: 500;

        &:hover,
        &:visited,
        &:focus {
          color: var(--emu-semantic-colors-primary-blue-300);
          background-color: var(--emu-common-colors-white);
        }
      }

      a,
      a[aria-selected=true] {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    }

    &__tabpanels {
      display: flex;
      flex-direction: row-reverse;
      min-height: 100vh;

      @include aem-editor-view() {
        min-height: auto;
      }

      .tabpanel__container-map .googlemaps {
        height: 100vh;
        margin: 0;

        @include aem-editor-view() {
          height: auto;
        }

        @include mq($emu-semantic-sizing-breakpoints-large) {
          position: fixed;
          top: 0;
          left: 390px;
          //-390px is the width of the ICL Feed (clinics list) component located on the left side of the google map.
          max-width: calc(100% - 390px);
          width: 100%;

          &.not-sticky {
            position: absolute;
            bottom: 0;
            top: initial;
            left: 0;
            max-width: 100%;
          }
        }
      }
    }

    &__tabpanel {
      flex: 1 1 auto;
      position: relative;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        display: block;
      }

      &:first-child {
        @include mq($emu-semantic-sizing-breakpoints-large) {
          //-390px is the width of the ICL Feed (clinics list) component located on the left side of the google map.
          max-width: calc(100% - 390px);
          overflow: hidden;
        }
      }

      &:last-child {
        @include mq($emu-semantic-sizing-breakpoints-large) {
          max-width: 390px;
        }
      }
    }
  }

  &.aaaem-tabs__horizontal-ui>ul:first-child li {
    margin-right: 0;
    margin-left: 0;
  }
}