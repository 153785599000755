.cmp-text {
  a {
    color: var(--emu-semantic-colors-primary-blue-300);
    text-decoration: underline;
  }

  small {
    color: var(--emu-semantic-colors-secondary-grey-100);
    display: block;
    font-size: 12px;
    line-height: 18px;

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.c-text {
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  max-width: var(--emu-semantic-sizing-content-max-width);
  padding-left: 15px;
  padding-right: 15px;

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    margin-top: 40px;
  }

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    padding-left: 0;
    padding-right: 0;
  }
}