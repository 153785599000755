.p-clinic {
  padding-bottom: 200px;
  
  @include mq($emu-semantic-sizing-breakpoints-large) {
    padding-bottom: 110px;
  }

  header.header:not(.header--sticky) {
    background-color: var(--emu-semantic-colors-secondary-yellow-100);

    .aaaem-button__secondary-filled:visited {
      color: var(--emu-semantic-colors-primary-blue-300);
    }

    .aaaem-button__secondary-filled.aaaem-button {
      background-color: var(--emu-common-colors-white);
      color: var(--emu-semantic-colors-primary-blue-300);
    }

    .aaaem-button__secondary-filled:hover {
      border-color: var(--emu-common-colors-white);
    }
  }

  &-container {
    > .container:first-of-type > .aaaem-container {
      position: relative;
      
      /* necessary to have the correct height and space for the text positioned absolutely next to the ICL Detail */
      @include mq($emu-semantic-sizing-breakpoints-large) {
        min-height: 1650px;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        min-height: 1800px;
      }
    }
  
    .icldetail {
      + .text {
        @include mq($emu-semantic-sizing-breakpoints-large) {
          margin-bottom: 90px;
          max-width: 445px;
          position: absolute;
          right: 15px;
          top: 535px;
          //45px is added to account for the absolute shift to the right and left and right padding
          width: calc(50% - 45px);
        }
  
        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          //15px is added to account for the absolute shift to the right
          left: calc(50% + 15px);
          right: auto;
        }
  
        .aaaem-text {
          background-color: var(--emu-semantic-colors-transparent-secondary-grey-100-a10);
          margin-top: 10px;
          padding-bottom: 60px;
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 60px;
  
          @include mq($emu-semantic-sizing-breakpoints-large) {
            margin-top: 0;
            padding-bottom: 30px;
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 30px;
          }
          
          h4,
          h5,
          p {
            margin-bottom: 20px;
          }
  
          h5 {  
            &::before {
              background-color: var(--emu-semantic-colors-secondary-blue-200);
              content: "";
              display: block;
              margin-bottom: 30px;
              margin-top: 30px;
              height: 1px;
              width: 40px;
            }
          }
        }
      }
    }
  }
}