.p-find-a-clinic {
  .c-navigation-findaclinic.aaaem-button {
    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      background-color: var(--emu-common-colors-white);
      border-color: var(--emu-common-colors-transparent);
    }

    &:active,
    &:focus,
    &:hover {
      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        /* Both background and background-color have to be added to do overwrites */
        background: var(--emu-common-colors-white);
        background-color: var(--emu-common-colors-white);
        border-color: var(--emu-semantic-colors-primary-blue-300);
      }
    }
  }
}