.c-find-the-clinic-video {
  margin-top: 60px;
  margin-bottom: 60px;

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    margin-bottom: 120px;
    margin-top: 120px;
  }

  &.js-emu-inview {
    .c-find-the-clinic-video__content {
      animation-name: slideIn;
    }
  }

  .container {
    padding-right: 15px;
    padding-left: 15px;
  }

  &__content {
    animation-delay: 0.3s;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    opacity: 0;
    width: 100%;
    margin-top: 0;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    border-radius: 10px;
    -webkit-font-smoothing: antialiased;
    background-color: var(--emu-semantic-colors-secondary-yellow-100);
    border-radius: 10px;
    padding-top: 30px;
    padding-right: 30px;
    padding-bottom: 20px;
    padding-left: 30px;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      max-width: 540px;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      max-width: 730px;
      padding-top: 30px;
      padding-right: 35px;
      padding-bottom: 30px;
      padding-left: 35px;
    }
  }

  &__heading > .emu-title__text {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 0;

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-bottom: 30px;
    }
  }

  &__search {
    .emu-location-services__search-submit-cta {
      display: none;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        display: block;
      }
    }
  }
}