.c-article-carousel {
  margin-bottom: 60px;
  margin-top: 5px;
  position: relative;

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    margin-bottom: 80px;
  }

  &::before {
    content: '';
    position: absolute;
    background-color: var(
      --emu-semantic-colors-transparent-secondary-grey-100-a10
    );
    height: 432px;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;

    @include mq($emu-semantic-sizing-breakpoints-medium) {
      height: 500px;
    }
  }

  > .title {
    .emu-title {
      margin-left: 15px;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-left: 0;
      }
    }
  }

  .title {
    position: relative;
    max-width: var(--emu-semantic-sizing-content-max-width);
    margin-left: auto;
    margin-right: auto;
  }

  .carousel {
    position: relative;
    max-width: var(--emu-semantic-sizing-content-max-width);
    margin-left: auto;
    margin-right: auto;
  }

  .aaaem-carousel {
    &__content {
      display: flex;
    }

    &__item {
      position: relative;
      margin-bottom: 40px;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        margin-bottom: 60px;
      }

      &::before {
        background-color: var(--emu-semantic-colors-transparent-grey-200);
        bottom: 0;
        content: '';
        display: block;
        height: 1px;
        position: absolute;
        //-30px it is the space between elements of the slider.
        width: calc(100% - 30px);
        border-bottom: 1px solid
          var(--emu-semantic-colors-transparent-secondary-grey-100-a30);
      }
    }

    &__item-container .text {
      display: flex;
      align-items: stretch;
    }
  }

  .emu-title__text,
  .cmp-title__text {
    margin-bottom: 20px;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      margin-bottom: 15px;
    }
  }

  .image {
    margin-bottom: 30px;
  }

  .carousel__item {
    &__name {
      margin-bottom: 30px;
    }

    &__testimonial {
      p {
        margin-bottom: 20px;
        font-family: var(--emu-semantic-font-families-heading);
      }
    }
  }
}
