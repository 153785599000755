.emu-comparison-slider {
  &__slider {
    &__slot {
      .text {
        position: absolute;
        top: 10px;

        .cmp-text {
          background-color: var(--emu-semantic-colors-secondary-grey-300);
          border-radius: 15px;
          padding-bottom: 2px;
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 2px;

          p {
            color: var(--emu-semantic-colors-primary-blue-300);
            font-size: 14px;
            font-weight: 500;
            line-height: 25px;
          }
        }
      }

      &--first {
        .text {
          left: 10px;
        }
      }

      &--second {
        .text {
          right: 10px;
        }
      }
    }
  }

  &__handle {
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }

  img-comparison-slider {
    --handle-position-start: 90%;
  }
}