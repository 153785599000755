.emu-location-services__form {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.emu-location-services__country-select-wrapper {
  margin-right: 8px;
  flex: 3 1 100%;

  select {
    background-color: var(--emu-semantic-colors-transparent-white-100);
    border: none;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: var(--emu-semantic-colors-transparent-blue-200);
    color: var(--emu-semantic-colors-primary-blue-300);
    font-size: 18px;
    border-radius: 0;
    padding-left: 10px;
    line-height: 20px;

    &:invalid {
      color: var(--emu-semantic-colors-transparent-primary-blue-300-a60);
    }
  }
}

.emu-location-services__location-input-wrapper {
  flex: 3 1 auto;
  margin-bottom: 0;

  .emu-location-services__location-input {
    //styles for search icon
    padding-left: 40px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg stroke='rgba(0, 94, 184, 1)'%3E%3Cg%3E%3Cg transform='translate(-525.000000, -263.000000) translate(525.000000, 263.000000) translate(3.000000, 3.000000)'%3E%3Ccircle cx='5.5' cy='5.5' r='5'/%3E%3Cpath d='M13 13L9 9'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-size: 20px;
    background-position: left 10px center;
    background-repeat: no-repeat;

    //styles for input field
    border: none;
    border-radius: 0;
    border-bottom: solid 2px var(--emu-semantic-colors-transparent-blue-200);
    background-color: var(--emu-semantic-colors-transparent-white-100);
    color: var(--emu-semantic-colors-primary-blue-300);
    font-size: 18px;
    line-height: 20px;

    &::placeholder {
      color: var(--emu-semantic-colors-primary-blue-300);
      opacity: 0.6;
    }

    ~.emu-location-services__location-input-error,
    ~.emu-location-services__location-null-error {
      display: none;
      color: var(--emu-semantic-colors-secondary-red-100);
      font-size: 14px;
      padding-top: 2px;
      padding-right: 0;
      padding-bottom: 0;
      padding-left: 2px;
    }
  }
}

.c-find-the-clinic-video__search.js-has-location-null-error {
  .emu-location-services__location-input {
    border: 2px solid var(--emu-semantic-colors-secondary-red-100);
  }

  .emu-location-services__location-input-error {
    display: block;
  }
}

.emu-location-services__location-input,
.emu-location-services__search-submit-cta,
.emu-location-services__country-select-wrapper select {
  height: 50px;
}

.emu-location-services__search-submit-cta {
  flex: 0 0 auto;
  margin-left: 20px;
  padding-top: 9px;
  padding-right: 32px;
  padding-bottom: 9px;
  padding-left: 32px;
  border: 1px solid var(--emu-semantic-colors-primary-blue-300);
  border-radius: 25px;
  color: var(--emu-common-colors-white);
  background-color: var(--emu-semantic-colors-primary-blue-300);
  position: relative;
  font-weight: 500;
  text-decoration: none;
  font-size: 18px;
  line-height: 32px;
  cursor: pointer;
  text-transform: uppercase;

  &:active,
  &:focus,
  &:hover {
    background: linear-gradient(270deg, var(--emu-semantic-colors-primary-blue-300) 0%, var(--emu-semantic-colors-primary-blue-200) 100%);
  }
}

.emu-location-services__current-location-btn {
  max-width: 100%;
  font-size: 18px;
  line-height: 32px;
  padding: 0;
  border: none;
  background: var(--emu-common-colors-transparent);
  color: var(--emu-semantic-colors-primary-blue-300);
  text-decoration: underline;
  cursor: pointer;
  text-align: left;

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    text-align: center;
  }
}

.emu-location-services__location-input-wrapper,
.emu-location-services__search-submit-cta,
.emu-location-services__current-location-btn {
  &.hide {
    display: none;
  }
}


#emu-location-services__location-input-error {
  display: none;
}

//class names come from external google service
.pac-container {
  border-top: none;
  padding-bottom: 20px;

  .pac-item {
    border: none;
    font-size: 14px;
    line-height: 20px;
    padding-top: 20px;
    padding-right: 44px;
    padding-bottom: 0;
    padding-left: 44px;
  }

  // .pac-logo:after hiding google disclamer
  &.pac-logo:after,
  .pac-icon {
    display: none;
  }
}