.c-introtext {
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  max-width: 730px;
  padding-left: 15px;
  padding-right: 15px;

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    margin-bottom: 80px;
    margin-top: 80px;
    padding-left: 0;
    padding-right: 0;
  }

  &__title {
    p {
      color: var(--emu-semantic-colors-primary-blue-300);
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
      text-align: center;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        font-size: 32px;
        line-height: 48px;
      }
    }
  }

  &__footnote {
    p {
      font-size: 12px;
      line-height: 18px;
      margin-top: 10px;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        font-size: 14px;
        line-height: 20px;
        margin-top: 15px;
      }
    }
  }
}
