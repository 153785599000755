.icl-feed-container {
    .clinics {
      &__list {
        list-style: none;
        padding-left: 0;
        min-height: 100vh;
        overflow-anchor: none;

        @include aem-editor-view() {
            min-height: auto;
        }
      }
   
      &__show-more {
        margin-top: 40px;
        margin-bottom: 60px;
        display: flex;
        justify-content: center;
      }

      margin-bottom: 0.7rem;
      font-size: 18px;
      line-height: 26px;
      color: var(--emu-semantic-colors-primary-blue-300);
      font-family: var(--emu-semantic-font-families-body);
      font-weight: 500;
    }
  
    .item {
        padding-top: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        margin-bottom: 20px;
        margin-top: 40px;

        &:first-child {
            padding-top: 0;
            margin-top: 0;
        }

        &:after {
            content: "";
            position: absolute;
            display: block;
            bottom: 0;
            left: 20px;
            margin-bottom: -20px;
            //-40px is the top margin value
            width: calc(100% - 40px);
            border-bottom: 1px solid var(--emu-semantic-colors-transparent-secondary-grey-100-a30);
        }

        &--active {
            background-color: var(--emu-semantic-colors-transparent-primary-blue-300-a10);
        }
  
        &__image-container {
            padding-top: 62.85%;
            margin-bottom: 10px;
        }
    
        &__description {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding-left: 0;
        }
    
        &__distance,
        &__street,
        &__city, 
        &__zip-code {
            padding-right: 5px;
        }
    
        &__image {
            position: absolute;
            top: 0;
            left: 0;
        }
    
        &__heading {
            margin-top: 0px;
            margin-right: 0px;
            margin-bottom: 5px;
            margin-left: 0px;
            font-size: 18px;
            line-height: 1.44;
            letter-spacing: 0;
            font-weight: 500;
            color: var(--emu-semantic-colors-primary-blue-300);
            cursor: pointer;
        }
  
        &__description {
            margin-bottom: 20px;
            font-size: 14px;
            line-height: 1.43;
            letter-spacing: 0.5px;
            color: var(--emu-semantic-colors-secondary-grey-100);
        }
    
        &__buttons-container {
            display: flex;
        }
    
        &__button {
            position: relative;
        }
    
        &__button-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }

        &__link {
            color: var(--emu-semantic-colors-primary-blue-300);
            cursor: pointer;
            display: inline-block;
            font-family: var(--emu-semantic-font-families-body);
            font-size: 18px;
            font-weight: 400;
            height: 50px;
            line-height: 33px;
            min-width: 50px;
            overflow: hidden;
            position: relative;
            text-decoration: none;
            text-overflow: ellipsis;
            transition: .3s;
            white-space: nowrap;      
        }
        
        &__link-show-more,
        &__link-website,
        &__link-phone {
            color: var(--emu-common-colors-white);
            border: 1px solid var(--emu-semantic-colors-primary-blue-300);
            background-color: var(--emu-semantic-colors-primary-blue-300);
            border-radius: 25px;
            padding-top: 8px;
            padding-right: 20px;
            padding-bottom: 12px;
            padding-left: 20px;
            margin-right: 10px;
            font-weight: 400;

            &:hover {
                color: var(--emu-semantic-colors-primary-blue-300);
                border: 1px solid var(--emu-semantic-colors-primary-blue-300);
                background-color: var(--emu-common-colors-white); 
            }
        }

        &__link-clinic {
            border-radius: 50%;
            padding: 8px;
            margin-left: auto;
            border: 1px solid var(--emu-semantic-colors-primary-blue-300);

            &:hover {
                border-color: var(--emu-semantic-colors-primary-blue-300);
                color: var(--emu-common-colors-white);
            }
        }
    }

    .no-results {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: auto;
        padding-top: 0px;
        padding-right: 20px;
        padding-bottom: 0px;
        padding-left: 20px;
        transform: translateY(-50%);
        
        &__icon-container {
            display: flex;
            justify-content: center;
            height: 6rem;

            img {
                height: 100%;
            }
        }

        &__heading {
            text-align: center;
            margin-top: 15px;
            margin-right: 0px;
            margin-bottom: 15px;
            margin-left: 0px;
            color: var(--emu-semantic-colors-primary-blue-300);
            font-size: 35px;
            line-height: 40px;
        }

        &__text {
            text-align: center;
            line-height: 30px;
        }
    }
}

.clinics {

    &__results {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 20px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: var(--emu-semantic-colors-secondary-grey-100);
        text-align: center;

        &--number {
            padding-right: 8px;
        }

        &--location {
            color: var(--emu-semantic-colors-primary-blue-300);
        }

        &--reset {
            color: var(--emu-semantic-colors-primary-blue-300);
            border: none;
            background-color: initial;
            padding: 0;
            text-decoration: underline;
            cursor: pointer;
        }

        .tabpanel__container-map & {
            @include mq($emu-semantic-sizing-breakpoints-large) {
                display: none;
            }
        }
    }
}