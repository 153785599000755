
/**
 * ** 🚨 DO NOT EDIT THIS FILE DIRECTLY!
 * If you want to make edits make them in each appropriate token JSON file.
 */

$emu-semantic-colors-none: rgba(0,0,0,0) !default;
$emu-semantic-colors-primary-blue-100: #EDF0F5 !default;
$emu-semantic-colors-primary-blue-200: #00A9E0 !default;
$emu-semantic-colors-primary-blue-300: #005EB8 !default;
$emu-semantic-colors-secondary-yellow-100: #FFC600 !default;
$emu-semantic-colors-secondary-yellow-200: #FEF2B9 !default;
$emu-semantic-colors-secondary-blue-100: #002554 !default;
$emu-semantic-colors-secondary-blue-200: #005DBF !default;
$emu-semantic-colors-secondary-blue-300: #B2CEE9 !default;
$emu-semantic-colors-secondary-blue-400: #E5EEF7 !default;
$emu-semantic-colors-secondary-blue-500: #72A5D7 !default;
$emu-semantic-colors-secondary-grey-100: #75787B !default;
$emu-semantic-colors-secondary-grey-200: #979797 !default;
$emu-semantic-colors-secondary-grey-300: #E5E4E2 !default;
$emu-semantic-colors-secondary-grey-400: #E2E2E2 !default;
$emu-semantic-colors-secondary-red-100: #AD2300 !default;
$emu-semantic-colors-transparent-white-100: rgba(255, 255, 255, 0.1) !default;
$emu-semantic-colors-transparent-white-300: rgba(255, 255, 255, 0.3) !default;
$emu-semantic-colors-transparent-white-500: rgba(255, 255, 255, 0.5) !default;
$emu-semantic-colors-transparent-white-600: rgba(255, 255, 255, 0.6) !default;
$emu-semantic-colors-transparent-black-200: rgba(0, 0, 0, 0.2) !default;
$emu-semantic-colors-transparent-black-300: rgba(0, 0, 0, 0.3) !default;
$emu-semantic-colors-transparent-black-600: rgba(0, 0, 0, 0.6) !default;
$emu-semantic-colors-transparent-blue-200: rgba(0, 93, 191, 0.3) !default;
$emu-semantic-colors-transparent-blue-300: rgba(0, 94, 184, 0.3) !default;
$emu-semantic-colors-transparent-primary-blue-300-a10: rgba(0, 94, 184, 0.1) !default;
$emu-semantic-colors-transparent-primary-blue-300-a25: rgba(0, 94, 184, 0.25) !default;
$emu-semantic-colors-transparent-primary-blue-300-a30: rgba(0, 94, 184, 0.30) !default;
$emu-semantic-colors-transparent-primary-blue-300-a60: rgba(0, 94, 184, 0.60) !default;
$emu-semantic-colors-transparent-secondary-grey-100-a10: rgba(117, 120, 123, 0.10) !default;
$emu-semantic-colors-transparent-secondary-grey-100-a30: rgba(117, 120, 123, 0.30) !default;
$emu-semantic-colors-surface-light: #FFFFFF !default;
$emu-semantic-colors-surface-dark: #000000 !default;
$emu-semantic-colors-surface-modal-backdrop: rgba(0, 0, 0, 0.4) !default;
$emu-semantic-colors-actions-primary-light: #FF1D15 !default;
$emu-semantic-colors-actions-primary-dark: #CC0700 !default;
$emu-semantic-colors-actions-on-primary-light: #000000 !default;
$emu-semantic-colors-actions-on-primary-dark: #FFFFFF !default;
$emu-semantic-colors-text-light: #75787B !default;
$emu-semantic-colors-text-dark: #FFFFFF !default;
$emu-semantic-colors-foreground-light: #000000 !default;
$emu-semantic-colors-foreground-dark: #FFFFFF !default;
$emu-semantic-font-weight-500: 500 !default;
$emu-semantic-font-weight-bold: 700 !default;
$emu-semantic-font-weight-light: 300 !default;
$emu-semantic-font-weight-black: 900 !default;
$emu-semantic-font-weight-regular: 400 !default;
$emu-semantic-font-families-body: Avenir-Next, sans-serif !default;
$emu-semantic-font-families-heading: Avenir-Next, sans-serif !default;
$emu-semantic-font-families-mono: Courier !default;
$emu-semantic-spacing-none: 0px !default;
$emu-semantic-spacing-horizontal: 8px !default;
$emu-semantic-spacing-vertical: 8px !default;
$emu-semantic-spacing-one-line-height-wide: 32px !default;
$emu-semantic-spacing-one-line-height-narrow: 28px !default;
$emu-semantic-font-sizes-narrow-medium: 16px !default;
$emu-semantic-font-sizes-narrow-large: 20px !default;
$emu-semantic-font-sizes-narrow-xl: 32px !default;
$emu-semantic-font-sizes-narrow-xxl: 40px !default;
$emu-semantic-font-sizes-narrow-xxxl: 60px !default;
$emu-semantic-font-sizes-wide-medium: 18px !default;
$emu-semantic-font-sizes-wide-large: 24px !default;
$emu-semantic-font-sizes-wide-xl: 40px !default;
$emu-semantic-font-sizes-wide-xxl: 54px !default;
$emu-semantic-font-sizes-wide-xxxl: 80px !default;
$emu-semantic-line-heights-narrow-medium: 28px !default;
$emu-semantic-line-heights-narrow-large: 28px !default;
$emu-semantic-line-heights-narrow-xl: 40px !default;
$emu-semantic-line-heights-narrow-xxl: 48px !default;
$emu-semantic-line-heights-narrow-xxxl: 68px !default;
$emu-semantic-line-heights-wide-large: 32px !default;
$emu-semantic-line-heights-wide-medium: 32px !default;
$emu-semantic-line-heights-wide-xl: 48px !default;
$emu-semantic-line-heights-wide-xxl: 64px !default;
$emu-semantic-line-heights-wide-xxxl: 92px !default;
$emu-semantic-border-radius-xs: 4px !default;
$emu-semantic-border-radius-small: 8px !default;
$emu-semantic-border-radius-medium: 16px !default;
$emu-semantic-border-radius-large: 32px !default;
$emu-semantic-border-radius-none: 0px !default;
$emu-semantic-border-width-thin: 1px !default;
$emu-semantic-border-width-medium: 2px !default;
$emu-semantic-border-width-thick: 4px !default;
$emu-semantic-border-width-none: 0px !default;
$emu-semantic-outline-width-thin: thin !default;
$emu-semantic-outline-width-medium: medium !default;
$emu-semantic-outline-width-thick: thick !default;
$emu-semantic-outline-width-unset: unset !default;
$emu-semantic-sizing-none: 0px !default;
$emu-semantic-sizing-xxs: 1px !default;
$emu-semantic-sizing-xs: 8px !default;
$emu-semantic-sizing-small: 16px !default;
$emu-semantic-sizing-medium: 32px !default;
$emu-semantic-sizing-large: 64px !default;
$emu-semantic-sizing-xl: 128px !default;
$emu-semantic-sizing-xxl: 256px !default;
$emu-semantic-sizing-breakpoints-small: 390px !default;
$emu-semantic-sizing-breakpoints-medium: 576px !default;
$emu-semantic-sizing-breakpoints-large: 768px !default;
$emu-semantic-sizing-breakpoints-x-large: 992px !default;
$emu-semantic-sizing-breakpoints-xx-large: 1200px !default;
$emu-semantic-sizing-one-line-height-wide: 32px !default;
$emu-semantic-sizing-one-line-height-narrow: 28px !default;
$emu-semantic-sizing-content-max-width: 1140px !default;
$emu-semantic-sizing-modal-max-width: 1016px !default;
$emu-semantic-typography-narrow-headings-xxxl: 500 60px/68px Avenir-Next, sans-serif !default;
$emu-semantic-typography-narrow-headings-xxl: 500 40px/48px Avenir-Next, sans-serif !default;
$emu-semantic-typography-narrow-headings-xl: 500 32px/40px Avenir-Next, sans-serif !default;
$emu-semantic-typography-narrow-headings-large: 500 16px/28px Avenir-Next, sans-serif !default;
$emu-semantic-typography-narrow-headings-medium: 500 16px/28px Avenir-Next, sans-serif !default;
$emu-semantic-typography-narrow-body-regular: 400 16px/28px Avenir-Next, sans-serif !default;
$emu-semantic-typography-wide-headings-xxxl: 500 80px/92px Avenir-Next, sans-serif !default;
$emu-semantic-typography-wide-headings-xxl: 500 54px/64px Avenir-Next, sans-serif !default;
$emu-semantic-typography-wide-headings-xl: 500 40px/48px Avenir-Next, sans-serif !default;
$emu-semantic-typography-wide-headings-large: 500 24px/32px Avenir-Next, sans-serif !default;
$emu-semantic-typography-wide-headings-medium: 500 18px/32px Avenir-Next, sans-serif !default;
$emu-semantic-typography-wide-body-regular: 400 18px/32px Avenir-Next, sans-serif !default;

$semantic: (
  'semantic': (
    'colors': (
      'none': $emu-semantic-colors-none,
      'primary': (
        'blue': (
          '100': $emu-semantic-colors-primary-blue-100,
          '200': $emu-semantic-colors-primary-blue-200,
          '300': $emu-semantic-colors-primary-blue-300
        )
      ),
      'secondary': (
        'yellow': (
          '100': $emu-semantic-colors-secondary-yellow-100,
          '200': $emu-semantic-colors-secondary-yellow-200
        ),
        'blue': (
          '100': $emu-semantic-colors-secondary-blue-100,
          '200': $emu-semantic-colors-secondary-blue-200,
          '300': $emu-semantic-colors-secondary-blue-300,
          '400': $emu-semantic-colors-secondary-blue-400,
          '500': $emu-semantic-colors-secondary-blue-500
        ),
        'grey': (
          '100': $emu-semantic-colors-secondary-grey-100,
          '200': $emu-semantic-colors-secondary-grey-200,
          '300': $emu-semantic-colors-secondary-grey-300,
          '400': $emu-semantic-colors-secondary-grey-400
        ),
        'red': (
          '100': $emu-semantic-colors-secondary-red-100
        )
      ),
      'transparent': (
        'white': (
          '100': $emu-semantic-colors-transparent-white-100,
          '300': $emu-semantic-colors-transparent-white-300,
          '500': $emu-semantic-colors-transparent-white-500,
          '600': $emu-semantic-colors-transparent-white-600
        ),
        'black': (
          '200': $emu-semantic-colors-transparent-black-200,
          '300': $emu-semantic-colors-transparent-black-300,
          '600': $emu-semantic-colors-transparent-black-600
        ),
        'blue': (
          '200': $emu-semantic-colors-transparent-blue-200,
          '300': $emu-semantic-colors-transparent-blue-300
        ),
        'primary': (
          'blue': (
            '300': (
              'a10': $emu-semantic-colors-transparent-primary-blue-300-a10,
              'a25': $emu-semantic-colors-transparent-primary-blue-300-a25,
              'a30': $emu-semantic-colors-transparent-primary-blue-300-a30,
              'a60': $emu-semantic-colors-transparent-primary-blue-300-a60
            )
          )
        ),
        'secondary': (
          'grey': (
            '100': (
              'a10': $emu-semantic-colors-transparent-secondary-grey-100-a10,
              'a30': $emu-semantic-colors-transparent-secondary-grey-100-a30
            )
          )
        )
      ),
      'surface': (
        'light': $emu-semantic-colors-surface-light,
        'dark': $emu-semantic-colors-surface-dark,
        'modal-backdrop': $emu-semantic-colors-surface-modal-backdrop
      ),
      'actions': (
        'primary': (
          'light': $emu-semantic-colors-actions-primary-light,
          'dark': $emu-semantic-colors-actions-primary-dark
        ),
        'on-primary': (
          'light': $emu-semantic-colors-actions-on-primary-light,
          'dark': $emu-semantic-colors-actions-on-primary-dark
        )
      ),
      'text': (
        'light': $emu-semantic-colors-text-light,
        'dark': $emu-semantic-colors-text-dark
      ),
      'foreground': (
        'light': $emu-semantic-colors-foreground-light,
        'dark': $emu-semantic-colors-foreground-dark
      )
    ),
    'fontWeight': (
      '500': $emu-semantic-font-weight-500,
      'bold': $emu-semantic-font-weight-bold,
      'light': $emu-semantic-font-weight-light,
      'black': $emu-semantic-font-weight-black,
      'regular': $emu-semantic-font-weight-regular
    ),
    'fontFamilies': (
      'body': $emu-semantic-font-families-body,
      'heading': $emu-semantic-font-families-heading,
      'mono': $emu-semantic-font-families-mono
    ),
    'spacing': (
      'none': $emu-semantic-spacing-none,
      'horizontal': $emu-semantic-spacing-horizontal,
      'vertical': $emu-semantic-spacing-vertical,
      'oneLineHeight': (
        'wide': $emu-semantic-spacing-one-line-height-wide,
        'narrow': $emu-semantic-spacing-one-line-height-narrow
      )
    ),
    'fontSizes': (
      'narrow': (
        'medium': $emu-semantic-font-sizes-narrow-medium,
        'large': $emu-semantic-font-sizes-narrow-large,
        'xl': $emu-semantic-font-sizes-narrow-xl,
        'xxl': $emu-semantic-font-sizes-narrow-xxl,
        'xxxl': $emu-semantic-font-sizes-narrow-xxxl
      ),
      'wide': (
        'medium': $emu-semantic-font-sizes-wide-medium,
        'large': $emu-semantic-font-sizes-wide-large,
        'xl': $emu-semantic-font-sizes-wide-xl,
        'xxl': $emu-semantic-font-sizes-wide-xxl,
        'xxxl': $emu-semantic-font-sizes-wide-xxxl
      )
    ),
    'lineHeights': (
      'narrow': (
        'medium': $emu-semantic-line-heights-narrow-medium,
        'large': $emu-semantic-line-heights-narrow-large,
        'xl': $emu-semantic-line-heights-narrow-xl,
        'xxl': $emu-semantic-line-heights-narrow-xxl,
        'xxxl': $emu-semantic-line-heights-narrow-xxxl
      ),
      'wide': (
        'large': $emu-semantic-line-heights-wide-large,
        'medium': $emu-semantic-line-heights-wide-medium,
        'xl': $emu-semantic-line-heights-wide-xl,
        'xxl': $emu-semantic-line-heights-wide-xxl,
        'xxxl': $emu-semantic-line-heights-wide-xxxl
      )
    ),
    'borderRadius': (
      'xs': $emu-semantic-border-radius-xs,
      'small': $emu-semantic-border-radius-small,
      'medium': $emu-semantic-border-radius-medium,
      'large': $emu-semantic-border-radius-large,
      'none': $emu-semantic-border-radius-none
    ),
    'borderWidth': (
      'thin': $emu-semantic-border-width-thin,
      'medium': $emu-semantic-border-width-medium,
      'thick': $emu-semantic-border-width-thick,
      'none': $emu-semantic-border-width-none
    ),
    'outlineWidth': (
      'thin': $emu-semantic-outline-width-thin,
      'medium': $emu-semantic-outline-width-medium,
      'thick': $emu-semantic-outline-width-thick,
      'unset': $emu-semantic-outline-width-unset
    ),
    'sizing': (
      'none': $emu-semantic-sizing-none,
      'xxs': $emu-semantic-sizing-xxs,
      'xs': $emu-semantic-sizing-xs,
      'small': $emu-semantic-sizing-small,
      'medium': $emu-semantic-sizing-medium,
      'large': $emu-semantic-sizing-large,
      'xl': $emu-semantic-sizing-xl,
      'xxl': $emu-semantic-sizing-xxl,
      'breakpoints': (
        'small': $emu-semantic-sizing-breakpoints-small,
        'medium': $emu-semantic-sizing-breakpoints-medium,
        'large': $emu-semantic-sizing-breakpoints-large,
        'x-large': $emu-semantic-sizing-breakpoints-x-large,
        'xx-large': $emu-semantic-sizing-breakpoints-xx-large
      ),
      'oneLineHeight': (
        'wide': $emu-semantic-sizing-one-line-height-wide,
        'narrow': $emu-semantic-sizing-one-line-height-narrow
      ),
      'contentMaxWidth': $emu-semantic-sizing-content-max-width,
      'modalMaxWidth': $emu-semantic-sizing-modal-max-width
    ),
    'typography': (
      'narrow': (
        'headings': (
          'xxxl': $emu-semantic-typography-narrow-headings-xxxl,
          'xxl': $emu-semantic-typography-narrow-headings-xxl,
          'xl': $emu-semantic-typography-narrow-headings-xl,
          'large': $emu-semantic-typography-narrow-headings-large,
          'medium': $emu-semantic-typography-narrow-headings-medium
        ),
        'body': (
          'regular': $emu-semantic-typography-narrow-body-regular
        )
      ),
      'wide': (
        'headings': (
          'xxxl': $emu-semantic-typography-wide-headings-xxxl,
          'xxl': $emu-semantic-typography-wide-headings-xxl,
          'xl': $emu-semantic-typography-wide-headings-xl,
          'large': $emu-semantic-typography-wide-headings-large,
          'medium': $emu-semantic-typography-wide-headings-medium
        ),
        'body': (
          'regular': $emu-semantic-typography-wide-body-regular
        )
      )
    )
  )
);
