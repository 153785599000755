.footer-info {
  background-color: var(--emu-semantic-colors-secondary-blue-100);
  color: var(--emu-common-colors-white);
  overflow: hidden;
  padding-bottom: 12px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 40px;

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    padding-bottom: 42px;
  }

  .footer-disclaimer,
  .footer-list-notes {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--emu-semantic-sizing-content-max-width);
  }

  .cmp-text {
    a {
      color: var(--emu-common-colors-white);
    }
  }

  p,
  li {
    color: var(--emu-common-colors-white);
    font-size: 12px;
    line-height: 15px;
    opacity: 0.8;
    padding-bottom: 10px;

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  ol {
    list-style-position: inside;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: var(--emu-semantic-sizing-content-max-width);
    padding-left: 0;
  }

  p {
    margin-bottom: 20px;
  }
}