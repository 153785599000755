.c-search-location {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 69px;
  background-color: var(--emu-semantic-colors-secondary-blue-400);

  &.js-emu-inview {
    .c-search-area {
      animation-name: slideIn;
    }
  }

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    padding-top: 100px;
    padding-bottom: 120px;
  }

  &__countries {
    background-color: var(--emu-semantic-colors-secondary-blue-400);
    color: var(--emu-semantic-colors-primary-blue-300);
    margin-left: auto;
    margin-right: auto;
    max-width: var(--emu-semantic-sizing-content-max-width);
    padding-left: 15px;
    padding-right: 15px;

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .emu-location-services__country-select-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }

    label {
      font-size: 32px;
      font-weight: 500;
      line-height: 40px;
      margin-bottom: 30px;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        flex: 0 0 50%;
        margin-bottom: 0;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        font-size: 40px;
        line-height: 48px;
      }
    }

    select {
      background-color: var(--emu-semantic-colors-transparent-white-300);
      border: 0;
      border-bottom: 1px solid var(--emu-semantic-colors-primary-blue-300);
      color: var(--emu-semantic-colors-primary-blue-300);
      font-size: 18px;
      line-height: 32px;
      height: 50px;
      padding-left: 10px;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        flex: 0 0 42%;
      }
    }
  }

  .cmp-title__text {
    margin-bottom: 30px;
  }

  .c-search-area {
    animation-delay: 0.3s;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    max-width: var(--emu-semantic-sizing-content-max-width);
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    background-color: var(--emu-semantic-colors-secondary-blue-400);
    opacity: 0;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      flex-wrap: nowrap;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      padding-left: 0;
      padding-right: 0;
    }

    &.hidden {
      display: none;

      @include aem-editor-view() {
        display: block;
        opacity: 1;
      }
    }

    > .container {
      width: 100%;

      &:first-child {
        margin-bottom: 50px;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          margin-bottom: 0;
        }
      }

      &:last-child {
        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          margin-left: 8%;
          max-width: 42%;
        }
      }

      .aaaem-container {
        background-color: var(--emu-semantic-colors-secondary-blue-400);
      }
    }
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }

  .emu-location-services__form {
    flex-direction: column;
  }

  .c-search-location__input-container.js-has-location-null-error {
    .emu-location-services__location-input {
      border: 2px solid var(--emu-semantic-colors-error);
    }

    .emu-location-services__location-input-error {
      display: block;
    }
  }

  .emu-location-services__location-input {
    //magnifier/search icon
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cg stroke='%23005EB8'%3e%3cg%3e%3cg transform='translate(-525.000000%2c -263.000000) translate(525.000000%2c 263.000000) translate(3.000000%2c 3.000000)'%3e%3ccircle cx='5.5' cy='5.5' r='5'/%3e%3cpath d='M13 13L9 9'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
    background-color: var(--emu-semantic-colors-transparent-white-300);
    border-bottom-width: 1px;
    border-bottom-color: var(--emu-semantic-colors-primary-blue-300);
    color: var(--emu-semantic-colors-primary-blue-300);
    font-size: 18px;

    &::placeholder {
      color: var(--emu-semantic-colors-primary-blue-300);
    }
  }

  .emu-location-services__search-submit-cta {
    background-color: var(--emu-semantic-colors-primary-blue-300);
    color: var(--emu-common-colors-white);
    border-color: 1px solid var(--emu-semantic-colors-primary-blue-300);
    font-family: var(--emu-semantic-font-families-body);
    align-self: flex-end;
    font-weight: 300;
    margin-top: 20px;
  }

  &__btn-container {
    display: flex;
    flex-wrap: wrap;

    .button {
      display: block;
      transition: 0.3s;
      margin-right: 15px;
      margin-bottom: 20px;
      width: calc(50% - 15px);

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        width: calc(33.3% - 15px);
      }

      &.hidden {
        display: none;

        @include aem-editor-view() {
          display: block;
        }
      }

      .aaaem-button {
        width: 100%;
        background-color: transparent;
        border: 1px solid var(--emu-semantic-colors-primary-blue-300);
        color: var(--emu-semantic-colors-primary-blue-300);
        display: block;
        text-align: center;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          max-width: 250px;
        }

        &:hover {
          background: linear-gradient(90deg, var(--emu-semantic-colors-transparent-primary-blue-300-a25), transparent);
        }

        > span {
          width: 100%;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;

          display: block;
          text-wrap: nowrap;
        }
      }
    }
  }
}