.rtl {
  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  li,
  i,
  small,
  blockquote,
  a,
  .button {
    text-align: right;
  }

  ul,
  ol {
    list-style-position: inside;
  }

  ul {
    padding-right: 0;
  }

  a span {
    text-align: left;
  }

  .cmp-teaser {
    h1,
    h2 {
      text-align: center;
    }
  }

  .c-testimonialmedia {
    &__text {
      padding-right: 15px;

      .cmp-text {
        blockquote {
          margin-right: 0;
          width: auto;
        }
      }
    }
  }

  .c-treatmentareas {
    ul {
      direction: rtl;
    }
  }

  .c-faqshort {
    .aaaem-accordion {
      &__title {
        padding-right: 15px;
      }
    }
  }

  .c-topicnavigation {
    &__container {
      flex-direction: row-reverse;
    }

    .emu-navigation__menu--level-0 {
      direction: rtl;
    }
  }

  .c-icontext {
    > .title {
      .cmp-title__text {
        padding-right: 15px;
        text-align: left;

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          text-align: right;
        }
      }
    }
  }

  .c-article-carousel {
    > .title {
      .cmp-title {
        margin-left: 0;
        margin-right: 15px;
      }
    }
  }

  .c-progresscarousel {
    .cmp-title__text {
      padding-left: 0;
      padding-right: 15px;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        padding-right: 0;
      }
    }
  }

  .c-ba-carousel {
    .aaaem-carousel__indicator {
      text-indent: 10000em;
    }
  }

  .c-keytakeaways__container {
    > .title:not(:first-child) {
      &::after {
        left: unset;
        right: 0;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          right: 15px;
        }

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          right: 30px;
        }
      }
    }
  }

  .findaclinic__container {
    .findaclinic-hero {
      .findaclinic-hero-text {
        .emu-title__text {
          margin-left: auto;
          margin-right: 0;
        }
      }
    }
  }

  .findaclinic-hero {
    &-text {
      .locationservices {
        margin-left: auto;
        max-width: 290px;

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          max-width: 450px;
        }
      }
    }
  }
  
  .c-testimonial-video-carousel {
    .cmp-title__text {
      margin-right: 15px;
    }

    .cmp-text {
      margin-right: 15px;
      max-width: 950px;
    }
  }

  .icl-feed-container {
    .item__link-clinic {
      margin-right: 10px;
    }

    .item__description {
      direction: rtl;
    }

    .item__heading {
      margin-left: auto;
    }
  }

  .icl-detail {
    &__body {
      .icl-detail__button {
        margin-left: auto;
      }
    }

    &__text {
      direction: rtl;
    }
  }

  .footer-info {
    ol,
    ul {
      direction: rtl;
      padding-right: 0;
    }
  }
}